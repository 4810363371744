<template>
  <div class="goods-preview">
    <ul
      class="goods-preview__list"
      :class="[$$FormData.list_style]"
      :style="{
        paddingLeft: ($$FormData.page_edge ) * 0.5 + 'px',
        paddingRight: ($$FormData.page_edge ) * 0.5 + 'px',
        marginLeft: (-($$FormData.goods_space / 2) ) * 0.5 + 'px',
        marginRight: (-($$FormData.goods_space / 2) ) * 0.5 + 'px'
      }"
    >
      <li
        v-for="item in ($$FormData.goodsList.length ? $$FormData.goodsList : exampleGoods)"
        :key="item.goods_id"
        class="item-goods"
      >
        <div
          class="a-item-goods"
          :style="{margin: ($$FormData.goods_space / 2 ) * 0.5 + 'px'}"
          :class="[$$FormData.goods_chamfer, $$FormData.goods_style]"
        >
          <div class="image-goods">
            <div class="image-goods-inner" :style="{paddingTop: imagePaddingTopByRatio}">
              <div
                class="img-goods"
                :class="[$$FormData.image_fill]"
                :style="{backgroundImage: `url(${item.goods_image})`}"
              ></div>
            </div>
          </div>
          <div class="info-goods" :class="[`align-${$$FormData.text_align}`]">
            <div class="title-goods">
              <h3
                v-if="$$FormData.show_goods_name"
                class="name-goods"
                :style="{fontWeight: $$FormData.text_font_weight}"
              >{{ item.goods_name }}</h3>
              <p v-if="$$FormData.show_goods_desc && item.goods_desc" class="desc-goods">
                {{ item.goods_desc }}
              </p>
            </div>
            <div class="price-goods">
              <div v-if="$$FormData.show_goods_price" class="price" :style="{fontWeight: $$FormData.text_font_weight}">
                <span class="currency-price">￥</span>
                <span class="money-price">{{ item.goods_price }}</span>
              </div>
              <div v-if="$$FormData.show_goods_buy_btn" class="buy-btn" :class="['style-' + $$FormData.goods_buy_btn_style]">
                <i v-if="$$FormData.goods_buy_btn_style === 1" class="van-icon van-icon-cart-circle-o"></i>
                <i v-if="$$FormData.goods_buy_btn_style === 2" class="van-icon van-icon-add-o"></i>
                <i v-if="$$FormData.goods_buy_btn_style === 5" class="van-icon van-icon-add"></i>
                <i v-if="$$FormData.goods_buy_btn_style === 6" class="van-icon van-icon-shopping-cart-o"></i>
                <button v-else-if="$$FormData.goods_buy_btn_text" class="buy-text">
                  {{ $$FormData.goods_buy_btn_text }}
                </button>
              </div>
            </div>
          </div>
          <div v-if="$$FormData.show_goods_badge" class="badge-goods" :class="$$FormData.goods_badge_style">
            <i v-if="$$FormData.goods_badge_style === 'new'" class="van-icon van-icon-new-arrival"></i>
            <i v-else-if="$$FormData.goods_badge_style === 'hot'" class="van-icon van-icon-hot-sale"></i>
            <i v-else-if="$$FormData.goods_badge_style === 'NEW'" class="van-icon van-icon-new"></i>
            <i v-else-if="$$FormData.goods_badge_style === 'HOT'" class="van-icon van-icon-hot"></i>
            <img
              v-else-if="$$FormData.goods_badge_style"
              :src="$$FormData.goods_badge_style"
              :alt="$$FormData.goods_badge_style"
              class="image-badge"
            >
            <div v-else class="image-badge">
              <p>角标</p>
              <p>区域</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { uuid } from '@/utils/foundation'
export default {
  name: 'GoodsPreview',
  data() {
    return {
      exampleGoods: [
        {
          goods_id: uuid(),
          goods_name: '这里显示商品名称，最多显示1行。',
          goods_desc: '这里显示商品描述，最多显示1行。',
          goods_image: require('@/assets/placeholder-image.png'),
          goods_price: 99
        },
        {
          goods_id: uuid(),
          goods_name: '这里显示商品名称，最多显示1行。',
          goods_desc: '这里显示商品描述，最多显示1行。',
          goods_image: require('@/assets/placeholder-image.png'),
          goods_price: 99
        },
        {
          goods_id: uuid(),
          goods_name: '这里显示商品名称，最多显示1行。',
          goods_desc: '这里显示商品描述，最多显示1行。',
          goods_image: require('@/assets/placeholder-image.png'),
          goods_price: 99
        }
      ]
    }
  },
  computed: {
    /**
     * 通过图片比例计算paddingTop
     * @returns {string}
     */
    imagePaddingTopByRatio() {
      switch (this.$$FormData.image_ratio) {
        case '3:2': return '66.6667%';
        case '1:1': return '100%';
        case '3:4': return '133.333%';
        case '16:9': return '56.25%';
      }
      return '66.6667%'
    }
  }
}
</script>

<style lang="scss" scoped>
.goods-preview {
  width: 100%;
  overflow: hidden;
  .goods-preview__list {
    &.single {
      .name-goods {
        font-size: 16px;
      }
    }
    &.row-two {
      overflow: hidden;
      .item-goods {
        float: left;
        width: 50%;
      }
      .name-goods {
        height: 34px;
        max-height: 34px;
        font-size: 13px;
        line-height: 17px;
        -webkit-line-clamp: 2
      }
      .desc-goods {
        display: none;
      }
    }
    &.row-three,
    &.row-scroll {
      overflow: hidden;
      .item-goods {
        float: left;
        width: 33.333%;
      }
      .info-goods {
        padding: 0 8px 2px;
      }
      .name-goods {
        height: 34px;
        max-height: 34px;
        font-size: 13px;
        line-height: 17px;
        -webkit-line-clamp: 2
      }
      .desc-goods {
        display: none;
      }
      .price-goods .price {
        height: 16px;
        font-size: 16px;
        line-height: 16px;
      }
      .buy-btn .van-icon {
        height: 20px;
        font-size: 20px;
        line-height: 20px;
      }
      .badge-goods {
        .van-icon {
          font-size: 28px;
        }
        &.new .van-icon {
          top: 1px;
          &::after {
            top: 10px;
            left: 4px;
            width: 20px;
            height: 10px;
          }
        }
        &.hot .van-icon {
          top: 7px;
          left: 0;
          &::after {
            top: 0;
            left: 7px;
            width: 14px;
            height: 27px;
          }
        }
        &.NEW .van-icon {
          top: 0;
          left: 5px;
          font-size: 32px;
          &::after {
            top: 0;
            left: 7px;
            width: 19px;
            height: 23px;
          }
        }
        &.HOT .van-icon {
          top: 5px;
          left: 5px;
          &::after {
            top: 9px;
            left: 7px;
            width: 16px;
            height: 14px;
          }
        }
      }
    }
    &.column {
      .a-item-goods {
        overflow: hidden;
      }
      .image-goods {
        float: left;
        width: 155px;
        height: 155px;
        min-height: auto;
        margin-right: 10px;
        padding-top: initial;
      }
      .info-goods {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 155px;
        margin-left: 155px;
        padding: 0 12px 0 0;
        overflow: hidden;
        .title-goods {
          margin-top: 0;
        }
      }
      .name-goods {
        max-height: 40px;
        font-size: 14px;
        line-height: 18px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 12px;
        line-height: 20px;
      }
      .desc-goods {
        height: 16px;
        color: #969799;
        font-size: 12px;
        line-height: 16px;
        word-break: break-all;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    &.hybrid {
      .item-goods {
        float: left;
        &:nth-child(3n+1) {
          width: 100%;
        }
        &:nth-child(3n),
        &:nth-child(3n+2){
          width: 50%;
        }
      }
    }
    &.row-scroll {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: -10px;
      padding-bottom: 10px;
      overflow-x: auto;
      overflow-y: hidden;
      .item-goods {
        flex: 0 0 30%;
        float: none;
        width: 30%;
      }
    }
  }
  .a-item-goods {
    position: relative;
    display: block;
    box-sizing: border-box;
    min-height: 50px;
    overflow: hidden;
    color: #333333;
    background-color: #ffffff;
    transition: all ease .2s;
    &.round {
      border-radius: 8px;
    }
    &.card-shadow {
      box-shadow: 0 2px 4px rgba(0,0,0,0.06);
    }
    &.outline-white-bottom {
      border: 1px solid rgba(50,50,51,.1);
    }
    &.transparent {
      background-color: transparent;
      .info-goods {
        padding: 0;
      }
    }
  }
  .item-goods {
    overflow: hidden;
  }
  .image-goods {
    position: relative;
    .image-goods-inner {
      position: relative;
      max-height: 500px;
      overflow: hidden;
    }
    .img-goods {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      background-repeat: no-repeat;
      background-position: 50%;
      &.cover {
        background-size: cover;
      }
      &.contain {
        background-size: contain;
      }
    }
  }
  .info-goods {
    position: relative;
    padding: 0 12px 4px;
    &.align-center {
      .title-goods {
        text-align: center;
      }
      .price-goods {
        flex-direction: column;
        height: auto;
        .price {
          flex-shrink: 0;
          height: 44px;
          line-height: 44px;
        }
        .buy-btn {
          margin-bottom: 10px;
        }
      }
    }
    .name-goods {
      margin-top: 12px;
      max-height: 20px;
      margin-bottom: 4px;
      color: #323233;
      font-size: 15px;
      line-height: 20px;
      vertical-align: middle;
      word-break: break-all;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .desc-goods {
      height: 16px;
      color: #969799;
      font-size: 14px;
      line-height: 16px;
      word-break: break-all;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .price-goods {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 44px;
      .price {
        height: 20px;
        color: #f84343;
        font-size: 20px;
        line-height: 20px;
        word-break: break-all;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .currency-price {
        align-self: center;
        height: 14px;
        margin-right: 2px;
        font-size: 14px;
      }
    }
    .buy-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .van-icon {
        color: #f84343;
        font-size: 24px;
        line-height: normal;
        box-sizing: border-box;
      }
      &.style-4 .buy-text,
      &.style-8 .buy-text {
        color: #f84343;
        border-radius: 2px;
        border: 1px solid #f84343;
        background-color: #ffffff;
      }
      &.style-7 .buy-text {
        border-radius: 2px;
      }
      &.style-8 .buy-text {
        border-radius: 24px;
      }
      .buy-text {
        color: #ffffff;
        background-color: #f84343;
        font-size: 12px;
        height: 24px;
        line-height: normal;
        outline: none;
        border: none;
        border-radius: 24px;
        padding: 0 8px;
        box-sizing: border-box;
        transition: all ease .2s;
      }
    }
  }
  .badge-goods {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    &.new .van-icon {
      top: 11px;
      &::after {
        top: 10px;
        left: 4px;
        width: 28px;
        height: 17px;
      }
    }
    &.hot .van-icon {
      top: 10px;
      left: 5px;
      &::after {
        top: 0;
        left: 10px;
        width: 17px;
        height: 35px;
      }
    }
    &.NEW .van-icon {
      left: 4px;
      &::after {
        top: 0;
        left: 7px;
        width: 13px;
        height: 25px;
      }
    }
    &.HOT .van-icon {
      top: 10px;
      left: 10px;
      &::after {
        top: 9px;
        left: 7px;
        width: 24px;
        height: 22px;
      }
    }
    .van-icon {
      position: relative;
      color: #f84343;
      font-size: 38px;
      line-height: normal;
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: #ffffff;
      }
    }
    .image-badge {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      background-color: #86b6cb;
      color: #f84343;
      font-size: 12px;
    }
  }
}
</style>
